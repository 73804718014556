import { IconButton } from '@/Components'
import { FynbosLogo } from '@/Components/Logos'
import { Link, usePage } from '@inertiajs/react'
import * as Dialog from '@radix-ui/react-dialog'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, ReactNode, useEffect, useState } from 'react'

type NavDrawerProps = {
  children?: ReactNode
}

export const NavDrawer: FC<NavDrawerProps> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const { url } = usePage()

  useEffect(() => {
    setOpen(false)
  }, [setOpen, url])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <IconButton>menu</IconButton>
      </Dialog.Trigger>
      <AnimatePresence>
        {open ? (
          <Dialog.Portal forceMount>
            <Dialog.Overlay className='fixed inset-0 z-40 bg-scrim/75 backdrop-blur-sm data-[state=closed]:animate-overlayHide data-[state=open]:animate-overlayShow' />

            <Dialog.Content asChild>
              <motion.div
                initial={{ x: '-100%' }}
                animate={{ x: 0 }}
                exit={{ x: '-100%' }}
                transition={{ ease: 'easeInOut', duration: 0.3 }}
                className='fixed inset-y-0 left-0 z-50 flex h-dvh min-w-max flex-col bg-white focus:outline-none'
              >
                <div className='flex gap-4 p-4'>
                  <Dialog.Trigger asChild>
                    <IconButton>menu_open</IconButton>
                  </Dialog.Trigger>
                  <Dialog.DialogTitle asChild>
                    <Link href='/'>
                      <FynbosLogo />
                    </Link>
                  </Dialog.DialogTitle>
                  <Dialog.Description className='hidden'>
                    Navigation menu
                  </Dialog.Description>
                </div>
                <ScrollArea.Root className='relative flex h-full w-[18.875rem] grow overflow-hidden p-4'>
                  <ScrollArea.Viewport className='h-full w-full'>
                    {children}
                  </ScrollArea.Viewport>
                  <ScrollArea.Scrollbar
                    className='flex touch-none select-none p-0.5 transition-colors duration-[160ms] ease-out hover:bg-slate-50 data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col'
                    orientation='vertical'
                  >
                    <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-slate-300 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
                  </ScrollArea.Scrollbar>
                </ScrollArea.Root>
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        ) : null}
      </AnimatePresence>
    </Dialog.Root>
  )
}

NavDrawer.displayName = 'ListItem'
